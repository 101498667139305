/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import {STATUS_TYPE} from '../../ux/ux';
import t from '../../i18n/locale-keys';

class FIDO2Handler {
    constructor(showStatus) {
        this.showStatus = showStatus;
    }

    webAuthnConfig = {
        timeout: 30000 // 30 seconds
    };

    checkBrowserSupportsWebAuthn() {
        // check for secure context

        if (!window.isSecureContext) {
            this.showStatus(t.webAuthenticationInsecureConnection(), STATUS_TYPE.ERROR);
            return false;
        }

        // check for WebAuthn CR features
        if (window.PublicKeyCredential === undefined ||
            typeof window.PublicKeyCredential !== 'function' ||
            typeof window.PublicKeyCredential.isUserVerifyingPlatformAuthenticatorAvailable !== 'function') {
            this.showStatus(t.webAuthenticationNotSupported(), STATUS_TYPE.ERROR);
            return false;
        }
        return true;
    }
}

export default FIDO2Handler;
