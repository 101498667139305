/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import {touchIdEnroll} from '../../../api/devices/touchid-device.api';
import t from '../../../i18n/locale-keys';
import {STATUS_TYPE} from '../../../ux/StatusIndicator';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import TextField from '../../TextField';

class TouchIdMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        const {template} = props;
        const accountName = template.isEnrolled && template.data ? template.data.accountName : this.getBaseUsername();
        generateFormChangeHandler(this, { accountName }, {
            allowChange: true
        });
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    authenticationInfoSavable() {
        return this.authenticationInfoChanged()
            || (!this.props.template.isEnrolled && !!this.state.form.accountName);
    }

    getBaseUsername() {
        //Grabs username without repo qualifier "repo\user", returns an empty string if undefined
        return this.props.authentication.username ? this.props.authentication.username.match(/[\w-]*$/)[0] : '';
    }

    finishEnroll() {
        const {accountName} = this.state.form;
        if (!accountName.length) {
            return Promise.reject(t.userNameRequired());
        }

        this.props.showStatus(t.waitPlease(), STATUS_TYPE.INFO);

        return this.props.registerPromise(
            touchIdEnroll({userName: accountName})
        ).then((data) => {
            const {publicKey, userId} = data;
            const enrollData = {publicKey, userId, accountName};
            return this.props.doEnrollWithBeginProcess(enrollData);
        }).then((response) => {
            if (response.status !== 'FAILED') {
                return Promise.resolve(response);
            }
            else {
                throw response.msg;
            }
        });
    }

    render() {
        const accountNameInput = (
            <TextField
                disabled={this.props.readonlyMode}
                id="account_name"
                label={t.touchIdAccountName()}
                name="accountName"
                onChange={this.handleChange}
                value={this.state.form.accountName}
            />
        );

        return (
            <Authenticator
                description={t.touchIdDescription()}
                {...this.props}
            >
                {accountNameInput}
            </Authenticator>
        );
    }
}

export default TouchIdMethod;