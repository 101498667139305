/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import {STATUS_TYPE} from '../../../../ux/ux';
import FIDO2Handler from '../../../../api/devices/fido2-device.api';
import {WebAuthnApp} from 'webauthn-simple-app';

class FIDO2Test extends React.PureComponent {
    constructor(props) {
        super(props);

        this.fido2Handler = new FIDO2Handler(this.props.showStatus);
        this.props.setTestButtonAvailability(false);
    }

    componentDidMount() {
        // check if the browser supports webauthn
        if (this.fido2Handler.checkBrowserSupportsWebAuthn()) {
            this.props.doTestLogon()
            .then((response) =>{
                if (response.status === 'MORE_DATA') {
                    this.props.showStatus(response.msg, STATUS_TYPE.INFO);
                    this.startLogon();
                }
            });
        }
    }

    getWebAuthnConfig = () => {
        const WAConfig = this.fido2Handler.webAuthnConfig;
        WAConfig.username = this.props.username;
        WAConfig.loginChallengeEndpoint = this.getRegisterUrl();
        WAConfig.loginResponseEndpoint = this.getRegisterUrl();
        return WAConfig;
    };

    getRegisterUrl = () => {
        return `/fido2/${this.props.getLogonProcessId()}/login`;
    };

    startLogon = () => {
        const WAConfig = this.getWebAuthnConfig();
        const waApp = new WebAuthnApp(WAConfig);
        return this.props.registerPromise(
                waApp.login()
            ).then((result) => {
                if (result.status === 'ok') {
                    this.props.setAsyncLogon(() => {}, true);
                }
                else {
                    this.props.markTestComplete(false, result.errorMessage);
                }
            })
            .catch((err) => {
                this.props.markTestComplete(false, err.message);
            });
    };

    render() {
        return null;
    }
}

export default FIDO2Test;
