/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';

class QRCodeComponent extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            qrcode: null
        };

        this.qrcoderef = React.createRef();
    }

    componentDidMount() {
        this.setState({
            qrcode: new window.QRCode(this.qrcoderef.current, this.getOptions())
        });
    }

    componentWillUnmount() {
        this.state.qrcode.clear();
    }

    getOptions() {
        return {
            text: this.props.text || '',
            width: 256,
            height: 256,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: window.QRCode.CorrectLevel.M
        };
    }

    render() {
        return <div ref={this.qrcoderef} className="authenticator-section qr-container" />;
    }
};

export default QRCodeComponent;
