/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import PropTypes from 'prop-types';
import React from 'react';
import Tile from './Tile';
import {methods} from '../../data/MethodData';
import {templateType} from '../../types/types';
import t from '../../i18n/locale-keys';

export default function MethodTile({categoryName, id, onClick, template, templateChoice}) {
    const methodId = template.methodId;
    const methodData = methods[methodId];
    var icon = 'lock_thick';
    if (methodData) {
        icon = methodData.icon;
    }

    let title;
    let description;
    let category;
    // When choosing among methods of the same methodId, display the available one using the "New" keyword
    if (templateChoice && !template.isEnrolled) {
        title = t.authenticatorNew(template.methodTitle);
        description = template.methodTitle;
        category = '';
    }
    // Otherwise, display the method, with comment and categoryName taking precedence
    else {
        title = template.comment ? template.comment : template.methodTitle;
        description = template.comment ? template.methodTitle : '';
        category = categoryName || '';
    }

    return (
        <Tile
            category={category}
            description={description}
            icon={icon}
            iconTitle={template.methodTitle}
            id={id}
            isEnrolled={template.isEnrolled}
            isFullyEnrolled={template.isFullyEnrolled}
            onClick={onClick}
            title={title}
        />
    );
}

MethodTile.propTypes = {
    categoryName: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    template: templateType.isRequired,
    templateChoice: PropTypes.bool
};
