/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import TextField from '../../TextField';
import t from '../../../i18n/locale-keys';
import { createToast, STATUS_TYPE } from '../../../ux/ux';
import OTPVerificationWrapper from './OTPVerificationWrapper';

class EmailOTPMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        const {isEnrolled, data} = props.template;
        let email = '';
        if (isEnrolled && data) {
            email = data.email || '';
        }
        const initialOtherState = {defaultRecipient: null};
        generateFormChangeHandler(this, {email}, initialOtherState);

        this.props.getDefaultRecipient(this.props.template.methodId).then(({defaultRecipient}) => {
            this.setState({defaultRecipient});
        });
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled || this.authenticationInfoChanged();
    }

    checkOverrideInput() {
        const userEmail = this.state.defaultRecipient || t.recipientUnknown();
        if(this.state.form.email === '' && userEmail === t.recipientUnknown() && this.state.dataDirty === true) {
            const description = t.emailOTPNoEmailRegistered();
            createToast({type: STATUS_TYPE.ERROR, description});
            return this.props.btnDisable = true;
        }
        return this.props.checkVerificationStatus() ? this.props.btnDisable = true : this.props.btnDisable = false;
    }

    finishEnroll() {
        const {email} = this.state.form;
        const formData = email.length ? {email} : {};
        return this.props.handleEnroll(formData); 
    };

    renderOverrideElements() {
        return (
            <React.Fragment>
                <div>
                    <label>{t.emailOverride()}</label>
                </div>
                <TextField
                    disabled={this.props.readonlyMode}
                    id="Email_Input_Field"
                    label={t.emailOverrideLabel()}
                    name="email"
                    value={this.state.form.email}
                    onChange={this.handleChange}
                />
            </React.Fragment>
        );
    }

    render() {
        const userEmail = this.state.defaultRecipient || t.recipientUnknown();
        const overrideElements = this.props.policies.emailOTPMethod.data.allowOverrideRecipient
            ? this.renderOverrideElements() : null;
        this.checkOverrideInput();

        return (
            <Authenticator
                description={t.emailOTPMethodDescription()}
                {...this.props}
            >
                <div>
                    <label>{t.emailPossessive()}</label>
                    <span className="directory-data">{userEmail}</span>
                </div>
                <div>
                    <label>{t.directoryFrom()}</label>
                </div>
                {overrideElements}
                {this.props.otpElements&& this.props.otpElements()}
            </Authenticator>
        );
    }
}

export default OTPVerificationWrapper(EmailOTPMethod);
