/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import ShowHidePassword from '../../ShowHidePassword';
import Authenticator from '../Authenticator';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import t from '../../../i18n/locale-keys';

class PasswordMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        generateFormChangeHandler(this, {
            password: '',
            confirmation: ''
        });
    }

    catchUnhandledLogonError = (failedResponseData) => {
        console.log(failedResponseData);
        throw failedResponseData;
    };

    doCheckPassword = (data, keepCamelCase = false) => {
        return this.props.registerPromise(
            this.props.doTestLogon(this.props.template.id, this.logonProcessId, data, keepCamelCase)
        ).catch(this.catchUnhandledLogonError)
            .then((response) => {
                const {result} = response;
                return result;
            });
    };

    finishEnroll() {
        const {password, confirmation} = this.state.form;
        if (password === confirmation && this.props.template.isEnrolled) {
            return this.doCheckPassword({answer: password})
                .then((response) => {
                    const {status} = response;
                    if (status === 'OK') {
                        this.setState(prevState => ({
                            form: {
                                ...prevState.form,
                                password: '',
                                confirmation: ''
                            }
                        }));
                        return Promise.reject(t.passwordUpdate());
                    }
                    return this.enrollNewPassword();
                });
        } else if (password === confirmation) {
            return this.enrollNewPassword();
        } else {
            return Promise.reject(t.passwordsNotMatching());
        }
    };

    enrollNewPassword() {
        const {password} = this.state.form;
        return this.props.doEnrollWithBeginProcess({password})
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                } else {
                    throw response.msg;
                }
            });
    };

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    render() {
        const label = (this.props.template.isEnrolled && !this.props.readonlyMode) ? t.passwordChange() : null;

        return (
            <Authenticator
                description={t.passwordMethodDescription()}
                {...this.props}
            >
                <ShowHidePassword
                    disabled={this.props.readonlyMode}
                    id="Password_Input_Field"
                    label={label}
                    name="password"
                    onChange={this.handleChange}
                    placeholder={t.passwordLabel()}
                    value={this.state.form.password}
                />
                <ShowHidePassword
                    disabled={this.props.readonlyMode}
                    id="Confirmation_Input_Field"
                    name="confirmation"
                    onChange={this.handleChange}
                    placeholder={t.passwordConfirmationLabel()}
                    value={this.state.form.confirmation}
                />
            </Authenticator>
        );
    }
}

export default PasswordMethod;
