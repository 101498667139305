/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import jsonFetch from '../json-fetch';
import t from '../../i18n/locale-keys';

const TOUCHID_ENROLL_SUFFIX = '/enroll';
const TOUCHID_LOGON_SUFFIX = '/logon';
const TOUCHID_SERVICE_URL = 'https://127.0.0.1:8443/api/v1/touchid';

const callTouchIdService = (urlSuffix, data) => {
    const options = {
        camelize: true,
        decamelize: true,
        method: 'POST',
        url: TOUCHID_SERVICE_URL + urlSuffix
    };

    options.data = data ? data : {};

    const getErrorMsg = (status) => {
        switch (status) {
            case 'USER_NOT_FOUND':
                return t.touchIdUserNotFound();
            case 'AUTHENTICATION_FAILED':
                return t.touchIdAuthenticationFailed();
            case 'USER_CANCEL':
                return t.touchIdBiometryUserCancelled();
            case 'NOT_AVAILABLE':
                return t.touchIdBiometryUnavailable();
            case 'NOT_ENROLLED':
                return t.touchIdBiometryNotEnrolled();
            case 'TIMEOUT':
                return t.touchIdTimeout();
            case 'LOCKOUT':
                return t.touchIdBiometryLocked();
            default:
                return t.touchIdUnexpectedStatus();
        }
    };

    return jsonFetch(options)
        .catch((failedResponseData) => {
            if (failedResponseData.status) {
                return Promise.reject(t.touchIdBiometryUnavailable());
            }

            return Promise.reject(t.touchIdServiceError());
        }).then((data) => {
            if (data.result) {
                return Promise.reject(getErrorMsg(data.result));
            }

            return data;
        });
};

export const touchIdEnroll = (data) => callTouchIdService(TOUCHID_ENROLL_SUFFIX, data);
export const touchIdLogon = (data) => callTouchIdService(TOUCHID_LOGON_SUFFIX, data);
