/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import QRCodeComponent from '../../../ux/QRCodeComponent';
import {STATUS_TYPE} from '../../../ux/ux';
import t from '../../../i18n/locale-keys';

class SmartphoneMethod extends React.PureComponent {
    constructor(props) {
        super(props);
        const { template } = this.props;
        const availableVendors = this.props.policies.smartphoneMethod.data.availableVendors.filter(vendor => vendor);
        const enrolledVendor = template.isFullyEnrolled && template.data.vendor ? template.data.vendor : null;

        this.state = {
            isEnrolling: false,
            qrData: '',
            vendor: enrolledVendor,
            availableVendors
        };

        // If offline authentication is disabled, we will hide the part of the description mentioning it.
        this.disableOffline = this.props.policies.smartphoneMethod.data.disableOffline;
    }

    authenticationInfoChanged() {
        return this.state.isEnrolling;
    }

    authenticationInfoSavable() {
        return false;
    }

    handleQRClick = () => {
        this.setState({ isEnrolling: true });
        const vendor = this.state.vendor !== null ? { vendor: this.state.vendor } : null;

        this.props.doEnrollWithBeginProcess(vendor)
            .then((response) => {
                if (response.status === 'MORE_DATA') {
                    this.scheduleAutoSubmit();
                    this.setState({ qrData: response.qrdata });
                }
                else {
                    this.props.showStatus(response.msg, STATUS_TYPE.ERROR);
                }
            });
    };

    scheduleAutoSubmit = () => {
        this.props.setAsyncEnroll((response) => {
            if (response.status === 'OK') {
                // Unlike other methods with async enrollment, the Smartphone enrollment only returns an empty string
                // on success instead of the expected 'Enrollment is complete' message.
                // To avoid this problem, let's set a success message if string is blank.
                const message = response.msg || t.enrollmentComplete();
                this.props.showStatus(message, STATUS_TYPE.OK);
            }
            else {
                this.props.showStatus(response.msg, STATUS_TYPE.ERROR);
                this.setState({ isEnrolling: false });
            }
        });
    };

    handleVendorChange = (e) => {
        e.preventDefault();
        this.setState({vendor: e.target.value});
    };

    render() {
        const qrElement = this.state.qrData ? <QRCodeComponent text={this.state.qrData} /> : null;

        let offlineDescription = null;
        if (!this.disableOffline) {
            offlineDescription = (
                <div className="description">
                    <ul>
                        <li>{t.smartphoneBackupMethodInfo()}</li>
                    </ul>
                </div>
            );
        }

        if (this.state.availableVendors.length === 1) {
            this.setState({vendor: this.state.availableVendors[0]});
        }

        const vendorSelect = this.state.availableVendors.length > 1 ? (
            <div className={'ias-input-container'}>
                <label htmlFor={'vendor_select'}>{t.smartphoneVendorSelect()}</label>
                <select disabled={this.state.isEnrolling || this.props.readOnlyMode}
                        id={'vendor_select'}
                        name={'vendor_select'}
                        onChange={this.handleVendorChange}
                >
                    {this.state.availableVendors.map((vendor) => (
                        <option key={vendor} selected={vendor === this.state.vendor} value={vendor}>
                            {vendor}
                        </option>
                        )
                    )}
                </select>
            </div>
        ) : null;

        const smartphoneEnrollInstructions = this.state.availableVendors.length > 1
            ? t.smartphoneEnrollVendorInstructions() : t.smartphoneEnrollInstructions();

        return (
            <Authenticator
                description={t.smartphoneMethodDescription()}
                {...this.props}
            >
                <div className="description">
                    {smartphoneEnrollInstructions}
                </div>

                {vendorSelect}

                <div className="authenticator-section">
                    <button
                        className="ias-button"
                        disabled={this.state.isEnrolling || this.props.readonlyMode}
                        id="QR_Code_Button"
                        onClick={this.handleQRClick}
                        type="button"
                    >
                        {t.buttonGetQRCode()}
                    </button>
                </div>

                {qrElement}
                {offlineDescription}
            </Authenticator>
        );
    }
}

export default SmartphoneMethod;
