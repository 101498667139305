/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './Login.scss';
import {AUTHENTICATION_STATES} from '../../reducers/authentication.reducer';
import {checkMultitenancyStatus, clearUser, handleOSPAuthRedirect, updateUsername}
    from '../../actions/authentication.actions';
import {HOMEPAGE_URL, viewDashboard} from '../../actions/navigation.actions';
import {connect} from 'react-redux';
import React from 'react';
import {Redirect} from 'react-router-dom';
import t from '../../i18n/locale-keys';
import Header from "../secondary-layout/Header";
import {LoadingIndicator} from "../../ux/ux";
import {parseQueryString} from '../../utils/url-functions';

class Login extends React.PureComponent {
    handleCancel = () => {
        this.props.clearUser();
    };

    handleSubmit = (event) => {
        event.preventDefault();

        const {status, username} = this.props.authentication;
        const redirect_uri = this.readRedirectUriParameter();
        if (status === AUTHENTICATION_STATES.USERNAME_ENTRY) {
            if ( redirect_uri ) {
                this.props.handleOSPAuthRedirect(username,redirect_uri)
            } else {
                this.props.handleOSPAuthRedirect(username)
            }
        } else {
            console.error('Error! There is no action on the login page for a user already logged in!');
        }
    };

    readRedirectUriParameter = () => {
        return parseQueryString(window.location.search)['redirect_uri'];
    };

    handleUsernameChange = (event) => {
        this.props.updateUsername(event.target.value);
    };

    // Check current URL against default
    isDefaultUrl = () => this.props.siteUrls[""].indexOf(window.location.hostname) !== -1;

    redirectToOSP = () => this.props.handleOSPAuthRedirect(this.props.authentication.username, this.readRedirectUriParameter());

    render() {
        let loginDialog;
        const authenticationStatus = this.props.authentication.status;

        switch(authenticationStatus) {
            case AUTHENTICATION_STATES.UNINITIALIZED:
                return null;
            case AUTHENTICATION_STATES.OSP_AUTHENTICATION:
                //Fetches tenant name based on host and will redirect to the OSP login page.
                this.props.handleOSPAuthRedirect();
                break;
            case AUTHENTICATION_STATES.FETCH_MULTITENANCY_STATUS:
                this.props.checkMultitenancyStatus();
                break;
            case AUTHENTICATION_STATES.USERNAME_ENTRY:
                if (this.props.authentication.multitenancyEnabled && this.isDefaultUrl()) {
                    loginDialog = (
                        <div className="ias-login-dialog">
                            <Header />
                            <form className="login-form" onSubmit={this.handleSubmit}>
                                <div className="ias-input-container">
                                    <input autoFocus
                                           name="username"
                                           onChange={this.handleUsernameChange}
                                           placeholder={t.loginEmailOrUsername()}
                                           type="text"
                                    />
                                </div>
                                <button className="ias-button">
                                    {t.buttonNext()}
                                </button>
                            </form>
                            <div className={'footer-content'}>
                                {t.aboutCopyright()}
                            </div>
                        </div>
                    )
                }
                else {
                    this.redirectToOSP();
                }
                break;
            case AUTHENTICATION_STATES.LOGGED_IN:
                // Don't save the login page in the history. User must log out to get back to it
                const routerState = this.props.location.state;
                if (routerState && routerState.referrer) {
                    return <Redirect to={routerState.referrer} />;
                }
                else {
                    return <Redirect to={HOMEPAGE_URL} />;
                }
            default:
                console.error('Error! There is no view on the login page for this authentication state!');
                break;
        }

        return (
            <div className={'ias-content-padding'}>
                {/* Display loading message if loginDialog content is not available */}
                {loginDialog || <LoadingIndicator message={t.loading()} />}
            </div>
        );
    }
}

const mapStateToProps = ({authentication, methodsDisplay: {policies}}) =>
    ({authentication, siteUrls: policies ? policies.publicUrlOptions.data.siteUrls : {} });
const mapDispatchToProps = {
    checkMultitenancyStatus,
    clearUser,
    handleOSPAuthRedirect,
    updateUsername,
    viewDashboard
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
