/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './FingerprintMethod.scss';
import React from 'react';
import Authenticator from '../Authenticator';
import {createToast, Dialog, STATUS_TYPE, StatusIndicator} from '../../../ux/ux';
import {captureFingerprint} from '../../../api/devices/fingerprint-device.api';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import {FingerprintHandmap, MULTIFINGER_IDS, MULTIFINGER_MAP_ORDER} from './FingerprintHandmap';
import t from '../../../i18n/locale-keys';
import TextField from "../../TextField";

class HANISMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        const {data, isEnrolled} = props.template;
        let mobilePhone = '';
        let idNumber = '';
        if (isEnrolled && data) {
            mobilePhone = data.mobilePhone || '';
            idNumber = data.idNumber || '';
        }

        const initialOtherState = { defaultRecipient: null, defaultIdNumber: null };
        generateFormChangeHandler(this, { mobilePhone, idNumber }, initialOtherState);

        this.props.getDefaultRecipient(props.template.methodId).then(({defaultRecipient, defaultIdNumber}) => {
            this.setState({defaultRecipient, defaultIdNumber});
        })
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled || this.authenticationInfoChanged();
    }

    noMobilePhoneRegistered() {
        const userMobilePhone = this.state.defaultRecipient || t.recipientUnknown();

        return this.state.form.mobilePhone === ''
            && userMobilePhone === t.recipientUnknown()
            && this.state.dataDirty === true;
    }

    noIdNumberRegistered() {
        const userIdNumber = this.state.defaultIdNumber || t.recipientUnknown();

        return this.state.form.idNumber === ''
            && userIdNumber === t.recipientUnknown()
            && this.state.dataDirty === true;
    }

    checkOverrideInput() {
        if (this.noMobilePhoneRegistered() || this.noIdNumberRegistered()) {
            if (this.noIdNumberRegistered()) {
                const description = t.hanisNoIdRegistered();
                createToast({type: STATUS_TYPE.ERROR, description});
            }
            if (this.noMobilePhoneRegistered()) {
                const description = t.hanisNoPhoneRegistered();
                createToast({type: STATUS_TYPE.ERROR, description});
            }

            return this.props.btnDisable = true;
        }

        return this.props.btnDisable = false;
    }

    finishEnroll() {
        const {mobilePhone, idNumber} = this.state.form;
        const formData = mobilePhone.length || idNumber.length ? { ...this.state.form } : {};

        return this.props.doEnrollWithBeginProcess(formData)
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                }
                else {
                    throw response.msg;
                }
            });
    }

    getPolicyData() {
        return this.props.policies.hanisMethod.data;
    }

    renderOverrideElements = (policy) => {
        const overridePhone = policy.allowOverridePhone ? (
            <TextField
                disabled={this.props.readonlyMode}
                id="Mobile_Input_Field"
                label={t.hanisOverridePhoneLabel()}
                name="mobilePhone"
                onChange={this.handleChange}
                value={this.state.form.mobilePhone}
            />
        ) : null;
        const overrideId = policy.allowOverrideId ? (
            <TextField
                disabled={this.props.readonlyMode}
                id="Id_Number_Input_Field"
                label={t.hanisOverrideIdLabel()}
                name="idNumber"
                onChange={this.handleChange}
                value={this.state.form.idNumber}
            />
        ) : null;
        return (
            <React.Fragment>
                <div>
                    <label>{t.hanisOverrideInfoLabel()}</label>
                </div>
                {overrideId}
                {overridePhone}
            </React.Fragment>
        );
    }

    render() {
        const policy = this.getPolicyData();
        const userMobilePhone = this.state.defaultRecipient || t.recipientUnknown();
        const userIdNumber = this.state.defaultIdNumber || t.recipientUnknown();
        const overrideElements = policy.allowOverridePhone || policy.allowOverrideId
            ? this.renderOverrideElements(policy) : null;
        this.checkOverrideInput();

        return (
            <Authenticator
                description={t.hanisMethodDescricption()}
                {...this.props}
            >
                <div>
                    <label>{t.hanisIdLabel()}</label>
                    <span className="directory-data">{userIdNumber}</span>
                </div>
                <div>
                    <label>{t.hanisPhoneLabel()}</label>
                    <span className="directory-data">{userMobilePhone}</span>
                </div>
                <div>
                    <label>{t.directoryFrom()}</label>
                </div>
                {overrideElements}
            </Authenticator>
        );
    }
}

export default HANISMethod;
