/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import * as types from '../actions/action-types';

// Keep track of the current language so language updates can trigger state reloads without having to use packages
// like react-i18next. Ideally, this would be done with React Context not Redux, but using the import syntax seems
// to break React Context in our project.

const initialState = {
    language: 'en'
};
function localeReducer(state = initialState, action) {
    return action.type === types.CHANGE_LANGUAGE ? { language: action.language } : state;
}

export default localeReducer;
