/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

let toastEventsBeforeLoad = [];
let toastHandler = null;

export function createToast(toast) {
    if (toastHandler) {
        toastHandler(toast);
    }
    else {
        toastEventsBeforeLoad.push(toast);
    }
}

export function onCreateToast(handler) {
    toastHandler = handler;
    toastEventsBeforeLoad.forEach((toast) => {
        handler(toast);
    });
    toastEventsBeforeLoad = [];
}

export default createToast;
