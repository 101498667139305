/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import t from '../../../i18n/locale-keys';

class SamlSpMethod extends React.PureComponent {
    authenticationInfoChanged() {
        return false;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled;
    }

    finishEnroll() {
        return this.props.doEnrollWithBeginProcess()
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                } else {
                    throw response.msg;
                }
            });
    };

    render() {
        return (
            <Authenticator
                description={t.samlSpMethodDescription()}
                {...this.props}
            >
            </Authenticator>
        );
    }
}

export default SamlSpMethod;
