/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';

class GenericTest extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            nemidParameters: null,
            nemidResponse: '',
            appletOrigin: '',
        }

        this.props.setTestButtonAvailability(false);
        this.props.doTestLogon(null)    // Empty call to doLogon to get applet information
            .then((response) => {
                this.setState({
                    nemidParameters: this.formatNemIDParameters(response.nemidParameters),
                    appletOrigin: response.appletOrigin
                });
            })
            .catch((error) => {
                this.props.markTestComplete(false, error)
            });
    }

    componentDidMount() {
        window.addEventListener('message', this.handleNemIDMessage);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleNemIDMessage);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.state.nemidParameters !== nextState.nemidParameters;
    }

    // Format the NemID Applet parameters to the values the Applet expects to correctly initialize it
    // Used to correctly breakout key names with underscores (pARAMSDIGEST -> PARAMS_DIGEST) as expected
    formatNemIDParameters = (params) => {
        const stringParams = JSON.stringify(params);
        return JSON.parse(stringParams, function(key, val) {
            if (key.length < 1) {
                return val;
            }
            switch(key.toUpperCase()) {
                case ('DIGESTSIGNATURE'):
                    this.DIGEST_SIGNATURE = val;
                    return;
                case ('CREDENTIALUPDATE'):
                    this.CREDENTIAL_UPDATE = val;
                    return;
                case ('DONOTSHOWCANCEL'):
                    this.DO_NOT_SHOW_CANCEL = val;
                    return;
                case ('PARAMSDIGEST'):
                    this.PARAMS_DIGEST = val;
                    return;
                case ('REMEMBERUSERID'):
                    this.REMEMBER_USERID = val;
                    return;
                case ('REMEMBERUSERIDINITIALSTATUS'):
                    this.REMEMBER_USERID_INITIAL_STATUS = val;
                    return;
                case ('SIGNTEXTFORMAT'):
                    this.SIGNTEXT_FORMAT = val;
                    return;
                case ('SIGNTEXTMONOSPACEFONT'):
                    this.SIGNTEXT_MONOSPACEFONT = val;
                    return;
                case ('SIGNTEXTREMOTEHASH'):
                    this.SIGNTEXT_REMOTE_HASH = val;
                    return;
                case ('SIGNTEXTTRANSFORMATIONID'):
                    this.SIGNTEXT_TRANSFORMATION_ID = val;
                    return;
                case ('SIGNTEXTURI'):
                    this.SIGNTEXT_URI = val;
                    return
                case ('SIGNPROPERTIES'):
                    this.SIGN_PROPERTIES = val;
                    return;
                case ('SPCERT'):
                    this.SP_CERT = val;
                    return;
                case ('SUPPRESSPUSHTODEVICE'):
                    this.SUPPRESS_PUSH_TO_DEVICE = val;
                    return
                case ('TRANSACTIONCONTEXT'):
                    this.TRANSACTION_TEXT = val;
                    return;
                default:
                    this[key.toUpperCase()] = val;
                    return;
            }
        })
    }

    handleNemIDMessage = (e) => {
        const message = JSON.parse(e.data);
        let {appletOrigin, nemidParameters} = this.state;

        if (e.origin !== appletOrigin) {
            this.props.markTestComplete(false, 'Received message from unexpected origin : ', e.origin);
            return
        }

        if (message.command === 'SendParameters') {
            let parameters = JSON.stringify({command: 'parameters', content: JSON.stringify(nemidParameters)});

            this.nemid_iframe.contentWindow.postMessage(parameters, appletOrigin);
        }

        if (message.command === 'changeResponseAndSubmit') {
            this.props.doTestLogon({nemidResponse: message.content}, false)
                .catch((error) => {
                    this.props.markTestComplete(false, error);
                });
        }
    }

    render() {
        const random = Math.floor(Date.now() / 1000);
        const mode = 'lmt' // Can be lmt or std
        const appletURL = `${this.state.appletOrigin}/launcher/${mode}/${random}`;
        const nemidIframe = !!this.state.nemidParameters ? (
            <iframe frameBorder={'0'}
                    id={'nemid_iframe'}
                    name={'nemid_iframe'}
                    ref={(f) => { this.nemid_iframe = f; }}
                    scrolling={'no'}
                    src={appletURL}
                    style={{width: '320px', height: '460px', border: 0}}
            />
        ) : null;

        return (
            <div className={'authenticator-centered'}>
                {nemidIframe}
            </div>
        );
    }
}

export default GenericTest;
