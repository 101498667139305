/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

/* Read from and write to local storage.
 * Catch errors in case the user has disabled local storage.
 */
export const storageItems = {
    ENDPOINT_SESSION_ID: 'ENDPOINT_SESSION_ID',
    LOGIN_PROCESS_ID: 'LOGIN_PROCESS_ID',
    LOGIN_SESSION_ID: 'LOGIN_SESSION_ID',
    TENANT_NAME: 'TENANT_NAME',
};

export function loadStorageItem(name) {
    try {
        return localStorage.getItem(name) || undefined;
    }
    catch (err) {
        // Ignore read errors
        console.error('Local storage read error:', err);
        return undefined;
    }
}

export function saveStorageItem(name, value) {
    try {
        localStorage.setItem(name, value);
    }
    catch (err) {
        // Ignore write errors
        console.error('Local storage write error:', err);
    }
}

export function clearStorageItem(name) {
    try {
        localStorage.removeItem(name);
    }
    catch (err) {
        // Ignore write errors
        console.error('Local storage write error:', err);
    }
}
