/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import PropTypes from 'prop-types';

const IconButton = ({className, icon, id, onClick, title}) => {
    let buttonClass = 'ias-button ias-icon-button';
    if (className) {
        buttonClass += ' ' + className;
    }
    return (
        <button
            className={buttonClass}
            id={id}
            onClick={onClick}
            title={title}
            type="button"
        >
            <i className={`ias-icon ias-icon-${icon}`} />
        </button>
    );
};

IconButton.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.string.isRequired,
    id: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

export default IconButton;
