/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

// We need to access the History object in Redux to programmatically navigate throughout the application.
// See https://stackoverflow.com/a/42320249/5016547

import createHistory from 'history/createBrowserHistory';

// Tell react-router our app root.
// Create-react-app injects the app root URL into process.env.PUBLIC_URL.
// In production, it uses the package.json "homepage" field, in development it uses "/".
const historyInstance = createHistory({
    basename: process.env.PUBLIC_URL + '/'
});

export default historyInstance;
