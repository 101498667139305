/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import StatusIndicator from '../../ux/StatusIndicator';
import React from 'react';
import PropTypes from 'prop-types';

function AuthenticatorStatus({statusMessage}) {
    if (!statusMessage) {
        return null;
    }

    return (
        <StatusIndicator type={statusMessage.type}>
            {statusMessage.description}
        </StatusIndicator>
    );
}

AuthenticatorStatus.propTypes = {
    statusMessage: PropTypes.shape({
        description: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired
    })
};

export default AuthenticatorStatus;
