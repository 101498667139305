/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './ChainTile.scss';
import PropTypes from 'prop-types';
import React from 'react';

export default function ChainTile(props) {
    const {id, isEnrolled, onClick, name} = props;
    let className = 'chain-tile';
    if (isEnrolled) {
        className += ' enrolled';
    }


    return (
        <div className={className} id={id} onClick={onClick} tabIndex="0">
            <h3>{name}</h3>

            <div className="chain-tile-content">
                <div className="chain-link" />
                {props.children}
            </div>
        </div>
    );
}

ChainTile.propTypes = {
    id: PropTypes.string,
    isEnrolled: PropTypes.bool,
    name: PropTypes.string.isRequired,
    onClick: PropTypes.func
};

ChainTile.defaultProps = {
    onClick: (() => {})
};
