/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

// Given a list of categories, will return the name of the given categoryId
export default function getCategoryName(categories, categoryId) {
    if (!categories) {
        return null;
    }

    for (let index = 0; index < categories.length; index++) {
        if (categories[index].id === categoryId) {
            return categories[index].name;
        }
    }

    console.error(`Category of id "${categoryId}" does not exist!`);
    return null;
}
