/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import t from '../../../i18n/locale-keys';
import ShowHidePassword from "../../ShowHidePassword";
import {generateFormChangeHandler} from "../../../utils/form-handler";


class LDAPPasswordMethod extends React.PureComponent {
    constructor(props) {
        super(props);

        generateFormChangeHandler(this, {
            password: '',
            newPassword: '',
            confirmation: '',
        });
    }

    finishEnroll() {
        const {password, newPassword, confirmation} = this.state.form;
        return this.props.doEnrollWithBeginProcess({answer: password, newPassword, confirmation})
            .then((response) => {
                if (response.status !== 'FAILED') {
                    return Promise.resolve(response);
                } else {
                    throw response.msg;
                }
            });
    };

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    render() {
        const label = (this.props.template.isEnrolled && !this.props.readonlyMode) ? t.currentPassword() : null;
        const isUsingPwmSaas = this.props.policies?.ldapMethod?.data?.ssprEnabled && this.props.policies?.ldapMethod?.data?.ssprUrl?.includes('${TENANT}');

        return (
            <Authenticator
                description={t.ldapMethodDescription()}
                {...this.props}
            >
                {isUsingPwmSaas ? null : (
                    <>
                        <ShowHidePassword
                            disabled={this.props.readonlyMode}
                            id="Password_Input_Field"
                            label={label}
                            name="password"
                            onChange={this.handleChange}
                            placeholder={t.currentPassword()}
                            value={this.state.form.password}
                        />
                        <ShowHidePassword
                            disabled={this.props.readonlyMode}
                            id="New_Password_Input_Field"
                            name="newPassword"
                            onChange={this.handleChange}
                            placeholder={t.newPassword()}
                            value={this.state.form.newPassword}
                        />
                        <ShowHidePassword
                            disabled={this.props.readonlyMode}
                            id="Confirmation_Input_Field"
                            name="confirmation"
                            onChange={this.handleChange}
                            placeholder={t.passwordConfirmationLabel()}
                            value={this.state.form.confirmation}
                        />
                    </>
                )}
            </Authenticator>
        );
    }
}

export default LDAPPasswordMethod;
