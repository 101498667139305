/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

export function getChainKey(chain) {
    return chain.chainUris[0] + '-' + chain.categoryName;
}

export function getChainTemplateKey(chain, template) {
    return chain.chainUris[0] + '-' + getTemplateKey(template);
}

export function getTemplateKey(template) {
    if (template.isEnrolled) {
        return template.methodId + '-' + (template.categoryId || 'default');
    }
    else {
        return template.methodId + '-add';
    }
}
