/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import {STATUS_TYPE} from '../../../../ux/ux';
import {touchIdLogon} from '../../../../api/devices/touchid-device.api';
import t from '../../../../i18n/locale-keys';

class TouchIdTest extends React.PureComponent {
    constructor(props) {
        super(props);
        this.props.setTestButtonAvailability(false);

        const {doTestLogon, markTestComplete, showStatus} = props;
        doTestLogon(null).then((response) => {
            const {userId, challenge} = response;
            if (challenge) {
                showStatus(t.touchIdAuthenticate(), STATUS_TYPE.INFO);
                return this.props.registerPromise(
                    touchIdLogon({ challenge, userId })
                );
            }
            else {
                markTestComplete(false, t.touchIdNoChallenge());
            }
        }).then((data) => {
            showStatus(t.waitPlease(), STATUS_TYPE.INFO);
            const {signature, digestName, hashName} = data;
            const logonData = {signature, digestName, hashName};
            doTestLogon(logonData);
        }).catch((errorMessage) => {
            markTestComplete(false, errorMessage);
        });
    }

    render() {
        return null;
    }
}

export default TouchIdTest;