/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */
import React from 'react';
import { connect } from 'react-redux';
import { SSO_DEFAULT_LOGOUT_REDIRECT } from '../../actions/navigation.actions';
import { parseQueryString } from '../../utils/url-functions';
import { logoutUser } from '../../actions/authentication.actions';

class SSOLogout extends React.PureComponent {

    logOutSSO = () => {
        const redirect_uri = parseQueryString(window.location.search)['redirect_uri'] || SSO_DEFAULT_LOGOUT_REDIRECT;
        this.props.logoutUser().then((success) => {
            window.location.href = redirect_uri;
        }).catch(err => console.error("Error", err));
    }

    componentDidMount() {
        this.logOutSSO();
    }

    render() {
        const ssoStyle={marginTop:'60px', textAlign: 'center'};
        return <div style={ssoStyle}></div>
    }
}

const mapDispatchToProps = {
    logoutUser
};

export default connect(null, mapDispatchToProps)(SSOLogout);
