/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import Authenticator from '../Authenticator';
import {generateFormChangeHandler} from '../../../utils/form-handler';
import t from '../../../i18n/locale-keys';

class FlexOTPMethod extends React.PureComponent {
    constructor(props) {
        super(props);
        const initialFormState = null;      //Unsure what method specific state might need to be tracked

        generateFormChangeHandler(this, initialFormState);
    }

    authenticationInfoChanged() {
        return this.state.dataDirty;
    }

    authenticationInfoSavable() {
        return !this.props.template.isEnrolled || this.authenticationInfoChanged();
    }

    render() {
        return (
            <Authenticator
                description={t.flexOTPMethodDescription()}
                {...this.props}
            />
        );
    }
}

export default FlexOTPMethod;