/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import PropTypes from 'prop-types';
import React from 'react';
import t from '../../../i18n/locale-keys';

export default function TestAuthenticatorButton({onClick, show}) {
    if (show) {
        return (
            <button className="ias-button" id="Test_Button" type="button" onClick={onClick}>
                {t.buttonTestMethod()}
            </button>
        );
    }

    return null;
}

TestAuthenticatorButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};
