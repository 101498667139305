/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import './App.scss';
import {abortPendingNavigation, continuePendingNavigation, HOMEPAGE_URL} from '../actions/navigation.actions';
import AvailableMethods from './methods-pages/AvailableMethods';
import ChainEnrollment from '../components/enrollment/ChainEnrollment';
import {connect} from 'react-redux';
import {ToastContainer} from '../ux/ux';
import EnrolledMethods from './methods-pages/EnrolledMethods';
import Header from './secondary-layout/Header';
import history from '../history';
import Languages from '../components/languages/Languages';
import Login from './login/Login';
import SSOLogout from './sso-logout/SSOLogout';
import MethodEnrollment from '../components/enrollment/MethodEnrollment';
import PrivateRoute from './PrivateRoute';
import React from 'react';
// Use the low-level Router rather than BrowserRouter so we can provide our own history object.
// See https://reacttraining.com/react-router/core/api/Router
import {Redirect, Router, Route, Switch} from 'react-router-dom';
import NavigationDialog from './NavigationDialog';
import i18next from 'i18next';
import {changeLanguage} from '../actions/locale.actions';
import {AUTHENTICATION_STATES} from "../reducers/authentication.reducer";

class App extends React.PureComponent {
    componentDidMount() {
        // Listen for language changes so we can update Redux store so translations reload.
        // Because Languages is a separate page, nearly all of the components reload translations on page load only.
        // The Header and Languages components are the two components that use the Redux store to reload translations.
        i18next.on('languageChanged', this.onLanguageChange);
    }

    componentWillUnmount() {
        i18next.off('languageChanged', this.onLanguageChange);
    }

    onLanguageChange = (language) => {
        this.props.changeLanguage(language);
        document.body.setAttribute('dir', i18next.dir(language));
    };

    render() {
        let isLoginPage = this.props.auth.status !== AUTHENTICATION_STATES.LOGGED_IN;
        let contentStyles = isLoginPage ? 'scrollable-content ias-login-screen' : 'scrollable-content';
        return (
            <Router history={history}>
                {!isLoginPage ? <Header /> : null}
                <div className={contentStyles}>
                    <main>
                        <Switch>
                            <PrivateRoute path="/" exact component={EnrolledMethods} />
                            <PrivateRoute path="/available/:type" exact component={AvailableMethods} />
                            <PrivateRoute path="/chains/:chainUri/:methodUri/:templateUri" component={ChainEnrollment}/>
                            <PrivateRoute path="/chains/:chainUri/:methodUri" component={ChainEnrollment} />
                            <PrivateRoute path="/chains/:chainUri" component={ChainEnrollment} />
                            <Route path="/languages" exact component={Languages} />
                            <Route path="/login" exact component={Login} />
                            <PrivateRoute path="/sso_logout"  exact component={SSOLogout}/>
                            <PrivateRoute path="/:methodUri/:templateType" exact component={MethodEnrollment} />
                            <PrivateRoute path="/:methodUri" exact component={MethodEnrollment} />

                            <Redirect to={HOMEPAGE_URL} />
                        </Switch>
                    </main>
                </div>

                <ToastContainer />
                <NavigationDialog />
            </Router>
        );
    }
}

const mapDispatchToProps = { abortPendingNavigation, changeLanguage, continuePendingNavigation };
const mapStateToProps = state => {
    return { auth: state.authentication };
};
export default connect(mapStateToProps, mapDispatchToProps)(App);
