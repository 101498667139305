/*
 * © Copyright 2014 - 2022 Micro Focus or one of its affiliates.
 *
 * The only warranties for products and services of Micro Focus and its affiliates and licensors ("Micro Focus") are as
 * may be set forth in the express warranty statements accompanying such products and services. Nothing herein should be
 * constituting an additional warranty. Micro Focus shall not be liable for technical or editorial errors or omissions
 * contained herein.
 *
 * The information contained herein is subject to change without notice.
 */

import React from 'react';
import {STATUS_TYPE} from '../../../../ux/ux';
import {captureFingerprint} from '../../../../api/devices/hanis-device.api';
import t from '../../../../i18n/locale-keys';

class GenericTest extends React.PureComponent {
    constructor(props) {
        super(props);

        this.props.setTestButtonAvailability(false);
        this.props.showStatus(t.fingerPlace(), STATUS_TYPE.INFO);

        this.props.registerPromise(captureFingerprint())
            .then((capture) => {
                this.props.doTestLogon({bioWsq: capture.Image}, false);
            })
            .catch((error) => {
                this.props.markTestComplete(false, error);
            });
    }

    render() {
        return null;
    }
}

export default GenericTest;
